const Api = function () {
    this._lang = window.Laravel.lang;
    this._apiBase = `https://mu1.panama.kz/${this._lang}`;
    // this._apiBase = `/${this._lang}`;
    moment.locale(this._lang);

    this.operateData = async function (url, method, data, contentType = 'application/json') {
        let params;
        switch (method) {
            case 'POST':
            case 'PUT':
                params = {
                    headers: {
                        'Content-Type': contentType
                    },
                    body: JSON.stringify(data)
                };
                break;
            default:
                if (data) {
                    params = {
                        headers: {
                            headers: {
                                'Content-Type': contentType
                            },
                            body: JSON.stringify(data)
                        }
                    }
                }
        }
        const res = await fetch(this._apiBase + url, {
            method: method,
            ...params
        });

        if (!res.ok && res.status !== 401 && res.status !== 400) {
            throw new Error(`Could not fetch ${url}, received ${res.status}`)
        }
        return res.json();
    };

    this.getActiveCowGroups = function (query) {
        return this.operateData(`/api/analytics/pie-chart/get-active-cow-groups${query ? '?' + query : ''}`);
    };

    this.getActiveCowRegions = function (query) {
        return this.operateData(`/api/analytics/pie-chart/get-active-cow-regions${query ? '?' + query : ''}`);
    };

    this.getActiveCowStatuses = function (query) {
        return this.operateData(`/api/analytics/pie-chart/get-active-cow-statuses${query ? '?' + query : ''}`);
    };

    this.getActiveCowCategories = function (query) {
        return this.operateData(`/api/analytics/pie-chart/get-active-cow-categories${query ? '?' + query : ''}`);
    };

    this.getActiveCowBreeds = function (query) {
        return this.operateData(`/api/analytics/pie-chart/get-active-cow-breeds${query ? '?' + query : ''}`);
    };

    this.getSaleCowGroups = async function (query) {
        const res = await this.operateData(`/api/analytics/column-chart/get-sale-cow-groups${query ? '?' + query : ''}`)
        return transformColumnData(res);
    }

    this.getSaleCowRegions = async function (query) {
        const res = await this.operateData(`/api/analytics/column-chart/get-sale-cow-regions${query ? '?' + query : ''}`)
        return transformColumnData(res);
    }

    this.getSaleCowSellingTypes = async function (query) {
        const res = await this.operateData(`/api/analytics/column-chart/get-sale-cow-selling-types${query ? '?' + query : ''}`)
        return transformColumnData(res);
    }

    this.getSaleCowEventTypes = async function (query) {
        const res = await this.operateData(`/api/analytics/column-chart/get-sale-cow-event-types${query ? '?' + query : ''}`)
        return transformColumnData(res);
    }

    this.getSaleCowBreeds = async function (query) {
        const res = await this.operateData(`/api/analytics/column-chart/get-sale-cow-breeds${query ? '?' + query : ''}`)
        return transformColumnData(res);
    }

    this.getImportCowGroups = async function (query) {
        const res = await this.operateData(`/api/analytics/column-chart/get-import-cow-groups${query ? '?' + query : ''}`)
        return transformColumnData(res);
    }

    this.getImportCowRegions = async function (query) {
        const res = await this.operateData(`/api/analytics/column-chart/get-import-cow-regions${query ? '?' + query : ''}`)
        return transformColumnData(res);
    }

    this.getImportCowBreeds = async function (query) {
        const res = await this.operateData(`/api/analytics/column-chart/get-import-cow-breeds${query ? '?' + query : ''}`)
        return transformColumnData(res);
    }

    function transformColumnData(data) {
        const items = data.content.items.map(function (item) {
           const {date, ...rest} = item;

           return {
               date: data.content.items.length > 12 ? moment(date).format('MMMM YYYY') : moment(date).format('MMM YYYY'),
               ...rest
           }
        });

        const set = data.content.set;

        return {
            items: items,
            set: set
        }
    }
};